<template>
	<div id="box" class="style_bg_color_main">
		<topmenu :comname="'index'"></topmenu>
		<!-- <div class="contactus_div_bg"></div>   -->
		<div class="bgml" id="introduce">
			<img src="@/assets/images/contactus_bg.png" class="bgDecorate" alt />
			<div class="widht1200">
				<!-- 线上云展 -->
				<div id="company">
					<div class="widht1200">
						<div class="title" id="onlineCloudExhibition">线上云展</div>
						<el-carousel height="520px" arrow="always" indicator-position="none" class="exhibitors_carousel_width">
							<el-carousel-item v-for="(item1, index) in companyList" :key="index" class="width_carousel">
								<ul class="compaylist width_carousel">
									<li v-for="item in item1" :key="item.id" :class="item.nvcCompanyName? 'bg-white': 'op0'">
										<template v-if="item">
											<a :href="item.url" :rel="noFollow(item.url)" target="_blank">
												<!-- <p class="iUiIdentifier" v-if="item.vBoothNumber == null?false:true">{{item.vBoothNumber}}</p>//展位号 -->
												<el-image
													:src="item.nvcLogo? item.nvcLogo: require('@/assets/images/dtmr.png')"
													fit="contain"
													:alt="
														item.nvcLogo
															? item.nvcCompanyName
															: ''
													"
													:title="item.nvcCompanyName"
												></el-image>
											</a>
											<a :href="item.url" :rel="noFollow(item.url)" target="_blank">
												<span :title="item.nvcCompanyName" class="company u-line-1" v-html="filterKeywords(item.nvcCompanyName)">
													<!-- {{item.nvcCompanyName}} -->
												</span>
											</a>
											<span class="spanPublic">
												{{ item.nvcContactPerson }}
												{{ item.nvcContactPost }}
											</span>
											<span class="spanPublic">
												电话：{{
												item.nvcContactNumber
												}}
											</span>
											<span class="productDetails" v-html="item.nvcProducts"></span>
											<a class="enter color182" :href="item.url" :rel="noFollow(item.url)" target="_blank">点击进入展厅</a>
										</template>
									</li>
								</ul>
							</el-carousel-item>
						</el-carousel>
						<router-link :to="{ name: 'enterprise', query: { id: 1 } }" class="font14 company_more">查看更多</router-link>
					</div>
				</div>
				<!-- 大会介绍 -->
				<div class="title" id="conferenceIntroduction">大会介绍</div>
				<div class="widht1200 introduce">
					<el-image style="height: 346px; width: 615px" :src="require('@/assets/images/hotel.jpg')" :preview-src-list="[
							require('@/assets/images/hotel.jpg'),
						]" fit="cover"></el-image>

					<div class="fr dhcontent font14 style_color_ash">
						<div style="height:256px;overflow: hidden; line-height:190%" v-html="filterKeywords(introduce)"></div>
						<br />
						<br />
						<router-link :to="{ name: 'exintroduce', query: { id: 1 } }">
							<a href="javascript:void(0);" class="details">查看详情</a>
						</router-link>
					</div>
				</div>
				<div class="title u-margin-top-30" id="contactus">联系方式</div>
				<div class="contactus">
					<div class="contactInformation">
						<div class="contal contactus_div">
							<!-- <i class="czqy"></i> -->
							<img src="@/assets/images/contal_1.png" alt />

							<span class="font16 style_color_main">- 参展热线 -</span>
							<p class="font16 style_color_ash">
								冯卫章
								<span class="dis_in_bl font16">199-1234-8021</span>
							</p>
						</div>

						<div class="contal contactus_div">
							<!-- <i class="czqy"></i> -->
							<img src="@/assets/images/contal_2.png" alt class="img_h" />
							<span class="font16 style_color_main">- 观展预约 -</span>
							<p class="font16 style_color_ash">
								郜彦良
								<span class="dis_in_bl font16">199-1234-8056</span>
							</p>
						</div>
						<div class="contal contactus_div">
							<!-- <i class="czqy"></i> -->
							<img src="@/assets/images/contal_3.png" alt class="img_h" />
							<span class="font16 style_color_main">- 媒体合作 -</span>
							<p class="font16 style_color_ash">
								张祥柏
								<span class="dis_in_bl font16">177-3233-5075</span>
							</p>
						</div>
						<div class="contal contactus_div">
							<!-- <i class="czqy"></i> -->
							<img src="@/assets/images/contal_4.png" alt class="img_h" />
							<span class="font16 style_color_main">- 会议地址 -</span>
							<span class="font14 style_color_ash">
								山西省吕梁市孝义市
								<br />崇文大街181号
							</span>
						</div>
					</div>
					<!-- <div class="contal fl contactus_div">
            <el-image :src="src" style="width:90px; height:90px; margin-top:25px; margin-bottom:20px" alt="冶金炉料博览会小程序"></el-image>
            <span class="font14 style_color_ash">冶金炉料博览会小程序</span>
					</div>-->
					<div class="floorplan">
						<!-- <el-image :src="require('@/assets/images/floorplan.png')" style="height: 440px;" 
						:preview-src-list="[require('@/assets/images/floorplan.png')]"></el-image>-->
						<el-image style="height: 440px; width: 100%;" :src="curUrl" fit="cover" :preview-src-list="[curUrl]"></el-image>
					</div>
				</div>
			</div>
		</div>
		<div id="dynamic">
			<div class="widht1200">
				<div class="title">大会动态</div>
				<div class="listinfor" v-if="dynamicList.length > 0">
					<div v-for="(item, index) in dynamicList" :key="index">
						<div v-if="index === 0" class="first fl">
							<a class="bgtsa" @click="jumpDteails(item)">
								<el-image :src="
										item.vImgUrl
											? item.vImgUrl
											: require('@/assets/images/dtmr.png')
									" :alt="item.vName" fit="cover" style="width:380px; height:200px;"></el-image>
							</a>
							<div class="contm1">
								<div class="fl font16">
									<span style="cursor:pointer;" @click="jumpDteails(item)" :title="item.vName" v-html="filterKeywords(item.vName)">
										<!-- {{
										item.vName && item.vName.length > 24
										? comJs.limitNum(item.vName, 24)
										: item.vName
										}}-->
									</span>
									<span class="font12 conferenceIntroduction">
										{{
										item.vAbstract &&
										item.vAbstract.length > 56
										? comJs.limitNum(
										item.vAbstract,
										56
										)
										: item.vAbstract
										}}
										<i
											v-if="
												item.vAbstract &&
													item.vAbstract.length > 56
											"
											class="color182 fontStyleNormal"
											style="cursor:pointer;"
											@click="jumpDteails(item)"
										>【more】</i>
									</span>
								</div>
							</div>
						</div>
						<div class="listcont fl">
							<div class="list" v-if="index > 0">
								<div class="contm">
									<a @click="jumpDteails(item)">
										<span class="tlew font16 td dynamic_color_blue" :title="item.vName" v-html="filterKeywords(item.vName)">
											<!-- {{
											item.vName &&
											item.vName.length > 28
											? comJs.limitNum(
											item.vName,
											28
											)
											: item.vName
											}}-->
										</span>
									</a>
									<div class="lun font14" v-html="filterKeywords(item.vAbstract)">
										<!-- {{
										item.vAbstract &&
										item.vAbstract.length > 40
										? comJs.limitNum(
										item.vAbstract,
										40
										)
										: item.vAbstract
										}}-->
									</div>
									<a @click="jumpDteails(item)" class="time_more">
										<img width="14" src="@/assets/images/arrow-right.png" alt />
									</a>
								</div>
								<div class="time fr dynamic_color_blue">
									<span class="font24 fr blue">
										{{
										item.dCreateTime | formatDate | todate1
										}}
									</span>
									<br />
									<span class="font12 fr">
										{{
										item.dCreateTime | formatDate | todate2
										}}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<router-link :to="{ name: 'dynamic' }" class="company_more font14">查看更多</router-link>
			</div>
		</div>
		<div id="forun">
			<div class="widht1200 bg_forun">
				<div class="title" id="foruminfor">大会集锦</div>
				<div class="foruminfor">
					<ul>
						<li v-for="item in collectionList" :key="item.id" style="width:386px; height:260px;">
							<el-card :body-style="{ padding: '5px' }" style="height:260px;">
								<video v-if="item.videoUrl" controls controlslist="nodownload" :poster="item.imgUrl" :src="item.videoUrl" style="height: 260px;width:386px"></video>

								<el-image
									v-else
									fit="cover"
									:src="
										item.imgUrlMin
											? item.imgUrlMin
											: item.imgUrl
									"
									:preview-src-list="[
										item.imgUrlMin
											? item.imgUrlMin
											: item.imgUrl,
									]"
									style="width: 100%; height:250px"
								></el-image>
							</el-card>
						</li>
					</ul>
					<router-link :to="{ name: 'specimens' }" class="company_more font14">查看更多</router-link>
				</div>
			</div>
		</div>

		<div class="mt15">
			<bottominfo></bottominfo>
		</div>
		<div class="t_menulist" v-if="ishowsa">
			<ul>
				<li @click="getejfl('conferenceIntroduction', 6)" :class="hoded == 6 ? 't_xzhouer' : ''">大会介绍</li>
				<li @click="getejfl('contactus', 2)" :class="hoded == 2 ? 't_xzhouer' : ''">联系方式</li>
				<li @click="getejfl('dynamic', 3)" :class="hoded == 3 ? 't_xzhouer' : ''">大会动态</li>
				<li @click="getejfl('forun', 5)" :class="hoded == 5 ? 't_xzhouer' : ''">大会集锦</li>
				<li class="t-gotop" @click="getejfl('box', 4)">
					<img :src="require('@/assets/images/jiantou.png')" />TOP
				</li>
			</ul>
		</div>
		<img src="@/assets/images/bgDecorate.png" class="bgDecorate3" alt />
	</div>
</template>

<script>
import topmenu from "@/components/public/top";
import bottominfo from "@/components/public/bottom";
export default {
	name: "index",
	data() {
		return {
			introduce: "",
			src: require("@/assets/images/position_tip.png"),
			dynamicList: [],
			dynamicNum: "",
			forumList: [],
			forumNum: "",
			companyList: [],
			companyNum: "",
			hoded: 0,
			ishowsa: false,
			curUrl: "",
			collectionList: [],
		};
	},
	methods: {
		noFollow(n) {
			if (n && n.indexOf("086") == -1) {
				return "nofollow";
			} else {
				return "";
			}
		},
		getBooth() {
			this.$axios
				.post("/api/rally/getExhibitionImgCollection")
				.then((res) => {
					if (res[0].length > 0) {
						let demo = res[0];
						this.curUrl = demo[0].imgUrl;
						// console.log(this.curUrl);
					}
				});
		},
		getejfl(num, state) {
			document.getElementById(num).scrollIntoView();
			if (state != 4) {
				this.hoded = state;
				console.log(this.hoded);
			}
		},
		handleScroll(e) {
			var scrollTop = e.srcElement.scrollingElement.scrollTop;
			if (scrollTop < 600) {
				this.ishowsa = false;
			} else {
				this.ishowsa = true;
			}
			if (scrollTop < document.getElementById("introduce").offsetTop) {
				this.hoded = 0;
			} else if (
				scrollTop >
					document.getElementById("onlineCloudExhibition")
						.offsetTop &&
				scrollTop <
					document.getElementById("conferenceIntroduction").offsetTop
			) {
				this.hoded = 1;
			} else if (
				scrollTop >
					document.getElementById("conferenceIntroduction")
						.offsetTop &&
				scrollTop < document.getElementById("contactus").offsetTop
			) {
				this.hoded = 6;
			} else if (
				scrollTop > document.getElementById("contactus").offsetTop &&
				scrollTop < document.getElementById("dynamic").offsetTop
			) {
				this.hoded = 2;
			} else if (
				scrollTop > document.getElementById("dynamic").offsetTop &&
				scrollTop < document.getElementById("forun").offsetTop
			) {
				this.hoded = 3;
			} else if (scrollTop > document.getElementById("forun").offsetTop) {
				this.hoded = 5;
			}
		},
		getintroduce() {
			//大会介绍
			this.$axios
				.post("/api/app/exhibition/getExhibitionDetail", {})
				.then((res) => {
					if (res[0].length > 0) {
						let infordata = res[0];
						this.introduce = infordata[0].vIntroduceContent;
						// console.log(this.introduce,"大会介绍文字");
					}
				});
		},
		getdynamic() {
			//大会动态
			let data = {
				current: 1,
				size: 7,
				isPage: true,
			};
			this.$axios
				.post(
					"/api/app/exhibitionDynamic/getExhibitionDynamicPageList",
					data
				)
				.then((res) => {
					if (res[0].length > 0) {
						console.log(res, "动态");

						this.dynamicList = res[0];
						console.log(this.dynamicList);
						// this.dynamicNum=res[1].total;
					}
				});
		},
		getforum() {
			//同期活动
			let data = {
				current: 1,
				size: 4,
				isPage: true,
			};
			this.$axios
				.post(
					"/api/app/exhibitionForum/getExhibitionForumPageList",
					data
				)
				.then((res) => {
					console.log(111111, res);
					if (res[0].length > 0) {
						let infordata = res[0];
						console.log(infordata);
						this.forumList = infordata;
						this.forumNum = res[1].total;
					}
				});
		},
		filterKeywords(label) {
			console.log("label", label);
			let labels = label ? label : "";
			if (labels.indexOf("刚玉") != -1) {
				let newData = this.comJs.keywordShow("刚玉", labels);
				if (newData.indexOf("矾土") != -1) {
					return this.comJs.keywordShow("矾土", newData);
				} else {
					return newData;
				}
			} else if (labels.indexOf("矾土") != -1) {
				return this.comJs.keywordShow("矾土", labels);
			} else {
				return labels;
			}
		},
		getinformation() {
			//参展企业
			let data = {
				current: 1,
				size: 120,
				isPage: true,
				itype: 1,
			};
			// console.log(data);
			this.$axios
				.post(
					"/api/app/exhibitionCompany/getExhibitionCompanyPageList",
					data
				)
				.then((res) => {
					console.log(res, "234e232323");
					if (res[0].length > 0) {
						let infordata = res[0];
						infordata.forEach((element) => {
							element.nvcProducts = this.filterKeywords(
								element.nvcProducts
							);
							// element.nvcCompanyName = this.filterKeywords(element.nvcCompanyName)
						});
						console.log(infordata, "参展企业");
						this.companyList = this.spArr(infordata, 6);
						this.companyNum == res[1].total;
						if (
							this.companyList[this.companyList.length - 1]
								.length %
								3 ==
							2
						)
							this.companyList[this.companyList.length - 1].push(
								""
							);
					}
				});
		},

		// 页面跳转判断
		jumpDteails(record) {
			if (!record.webUrl) {
				this.$router.push({
					// path: '/dynamicdetails',dynamic
					path: "/dynamicdetails",
					query: {
						id: record.id,
					},
				});
			} else {
				window.open(record.webUrl);
				// window.location.href = record.webUrl
			}
		},
		spArr(arr, num) {
			//arr是你要分割的数组，num是以几个为一组
			let newArr = []; //首先创建一个新的空数组。用来存放分割好的数组
			for (let i = 0; i < arr.length; ) {
				//注意：这里与for循环不太一样的是，没有i++
				newArr.push(arr.slice(i, (i += num)));
			}
			return newArr;
		},
		getCollectionList() {
			let data = {
				current: 1,
				size: 6,
				isPage: true,
				iIspcshow: 1,
				sort: 3,
			};
			this.$axios
				.post("/api/rally/getExhibitionCollectionPageList", data)
				// .post("/api/Applets/GetActivity")
				.then((res) => {
					console.log(res, "相对应栏目的图片");
					if (res[0].length > 0) {
						let infordata = res[0];
						for (let i = 0; i < infordata.length; i++) {
							// console.log(infordata[i].videoUrl)
							infordata[i].vImgUrlfn = infordata[i].imgUrl;
						}
						this.collectionList = infordata;
					}
				});
		},
	},
	created() {
		this.getintroduce();
		this.getdynamic();
		this.getforum();
		this.getinformation();
		this.getBooth();
		this.getCollectionList();
	},
	components: {
		topmenu,
		bottominfo,
	},
	computed: {},
	mounted() {
		window.addEventListener("scroll", this.handleScroll, true);
	},
	destroyed() {
		window.removeEventListener("scroll", this.handleScroll, true);
	},
	filters: {
		formatDate: function (value) {
			let date = new Date(value);
			let y = date.getFullYear();
			let MM = date.getMonth() + 1;
			MM = MM < 10 ? "0" + MM : MM;
			let d = date.getDate();
			d = d < 10 ? "0" + d : d;
			return y + "-" + MM + "-" + d;
		},
		todate1(value) {
			let data = value.split("-");
			return data[1] + "/" + data[2];
		},
		todate2(value) {
			let data = value.split("-");
			return data[0];
		},
	},
};
</script>
<style>
.color_orange {
	color: #e70012;
}
.u-line-1 {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
</style>
<style scoped>
/deep/.el-icon-arrow-left,
/deep/.el-icon-arrow-right {
	font-size: 30px;
}
/deep/.el-carousel__arrow {
	width: 60px;
	height: 60px;
}
.time {
	font-size: 13px;
	color: #999;
}
.blue {
	color: #182fb3;
}

.bottom {
	margin-top: 13px;
	line-height: 12px;
}

.button {
	padding: 0;
	float: right;
}

.image {
	/* width: 100%; */

	display: block;
}

.clearfix:before,
.clearfix:after {
	display: table;
	content: "";
}

.clearfix:after {
	clear: both;
}
.bgml {
	position: relative;
	z-index: 2;
	overflow: hidden;
	margin: 20px 0 0;
}
.title {
	font-size: 26px;
	line-height: 26px;
	padding: 45px 0 60px;
	color: #333;
	position: relative;
	font-weight: 700;
	/* border-bottom: 2px solid #CFCFCF; */
}
.title::before {
	width: 470px;
	height: 1px;
	content: "";
	background: #cfcfcf;
	position: absolute;
	top: 92px;
	left: 32%;
}
.title::after {
	position: absolute;
	width: 88px;
	height: 3px;
	left: 48.5%;
	margin-left: -25px;
	top: 90px;
	content: "";
	z-index: 7;
	background-color: #073290;
}
.dhcontent {
	width: 550px;
	height: 295px;
	border-radius: 2px;
	font-size: 16px;
	line-height: 30px;
	text-align: justify;
	text-indent: 2em;
	padding: 26px 0 26px 17px;
	color: #000;
	position: relative;
	z-index: 2;
}
.mt15 {
	margin-top: 15px;
}
.contactInformation {
	width: 460px;
	height: 440px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: space-between;
	padding: 0 20px 0 0;
}
.contactus {
	overflow: hidden;
	clear: both;
	margin-top: 25px;
	margin-bottom: 48px;
	display: flex;
	/* justify-content: center; */
	position: relative;
	z-index: 10;
}
.contactus .contal:nth-child(1) {
	background: linear-gradient(
		-45deg,
		rgba(22, 144, 255, 0.15) 1%,
		rgba(232, 239, 255, 0.15) 100%
	);
}
.contactus .contal:nth-child(2) {
	background: linear-gradient(
		-45deg,
		rgba(62, 201, 105, 0.15) 1%,
		rgba(232, 239, 255, 0.15) 100%
	);
}
.contactus .contal:nth-child(3) {
	background: linear-gradient(
		-45deg,
		rgba(252, 198, 74, 0.15) 1%,
		rgba(232, 239, 255, 0.15) 100%
	);
}
.contactus .contal:nth-child(4) {
	background: linear-gradient(
		-45deg,
		rgba(255, 69, 3, 0.15) 1%,
		rgba(232, 239, 255, 0.15) 100%
	);
}
.lxfs {
	font-size: 26px;
	font-weight: bold;
	color: #e9eaea;
	width: 30px;
	padding: 18px 36px;
	line-height: 30px;
}
.more {
	color: #343239;
	padding: 30px 0 40px;
	display: block;
	clear: both;
}
.contal {
	padding: 18px 33px;
	color: #e2e2e2;
	border: 1px solid rgba(24, 47, 179, 0.15);
	border-radius: 6px;
}
.contal span {
	display: block;
	line-height: 30px;
}
.contal span.font16 {
	font-weight: 700;
}
.czqy {
	background: url("../assets/images/common.png") no-repeat -48px -18px;
	width: 38px;
	height: 42px;
	display: block;
	margin: 10px auto;
}
.cgqy {
	background: url("../assets/images/common.png") no-repeat -248px -18px;
	width: 40px;
	height: 42px;
	display: block;
	margin: 10px auto;
}
.mehz {
	background: url("../assets/images/common.png") no-repeat -441px -18px;
	width: 46px;
	height: 42px;
	display: block;
	margin: 10px auto;
}
.dzmap {
	background: url("../assets/images/common.png") no-repeat -696px -18px;
	width: 38px;
	height: 42px;
	display: block;
	margin: 10px auto;
}
.listcont {
	border-left: 1px solid #efefef;
	/* padding-left: 23px; */
	margin-bottom: 30px;
}
.listinfor {
	height: 330px;
	overflow: hidden;
	margin-bottom: 40px;
}
.listinfor .list {
	display: block;
	/* background: #fafafa; */
	/* padding: 15px 10px; */
	width: 385px;
	/* margin-bottom: 15px; */
	position: relative;
	height: 90px;
}

.listinfor .first {
	width: 380px;
	position: relative;
	margin-right: 30px;
}
.listinfor div:nth-child(odd) {
	margin-right: 5px;
}
.contm {
	text-align: left;
	padding: 0 5px 0 15px;
	float: left;
	width: 270px;
}
.contm1 {
	border-radius: 0 0 5px 5px;
	text-align: left;
	float: left;
	width: 380px;
	height: 101px;
	margin-top: 22px;
}
.bgtsa {
	display: block;
	background-color: #f3f3f3;
}
.color-666 {
	width: 80px;
	padding-top: 5px;
}
.color-666 span {
	display: block;
	line-height: 30px;
	text-align: center;
}
.lun {
	line-height: 22px;
	padding-top: 20px;
	color: #999;
	text-overflow: -o-ellipsis-lastline;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	width: 270px;
}
.tlew {
	/* line-height: 22px; */
	color: #333;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 270px;
}
.time {
	/* font-size: 15px; */
	color: #999;
	width: 70px;
	height: 40px;
	/* padding: 20px 0; */
}
.foruminfor {
	overflow: hidden;
	position: relative;
	z-index: 2;
}
.foruminfor ul {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: wrap;
}
.foruminfor li {
	margin-bottom: 20px;
}
.forumcont {
	/* text-align: left; */
	display: inline-block;
	padding-top: 10px;
}
.forummore {
	padding-right: 15px;
}
.forummore img {
	margin-left: 5px;
}
.compaylist {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
}
.compaylist li {
	width: 338px;
	height: 190px;
	padding: 30px 24px;
	border-radius: 5px;
	margin-bottom: 20px;
}
.compaylist li .el-image {
	border: 1px solid #e4e4e4;
	width: 90px;
	height: 90px;
	border-radius: 5px;
	float: left;
	margin-right: 20px;
}
.spanPublic {
	display: inline-block;
	width: 225px;
	font-size: 14px;
	font-weight: 700;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
}
.productDetails {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	margin-top: 30px;
	text-align: left;
	line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
}
.enter {
	display: block;
	width: 100%;
	text-align: right;
	margin-top: 24px;
	margin-right: 10px;
}
.compaylist li .company {
	display: inline-block;
	width: 225px;
	font-size: 18px;
	font-weight: 700;
	color: #333;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
	margin-top: 12px;
}

.compaylist li:hover .el-image {
	border: solid 1px #4467cf;
}
.t_menulist {
	position: fixed;
	z-index: 10;
	top: 350px;
	left: 50%;
	margin-left: 650px;
	width: 64px;
	border: 1px solid #b2cffe;
}
.t_menulist li {
	display: block;
	cursor: pointer;
	background: #f1f5fe;
	border-bottom: 1px solid #f2f2f2;
	width: 40px;
	padding: 13.5px 12px;
	text-align: center;
	color: #666;
}
.t_menulist li:hover,
.t_menulist li.t_xzhouer {
	background: #4467cf;
	border-bottom: 1px solid #4467cf;
	color: #fff;
}
.t_menulist li.t-gotop {
	border: 0;
}
.t_menulist li.t-gotop img {
	display: block;
	margin: 5px auto;
}
.t_menulist li.t-gotop:hover {
	background: #4467cf;
	color: #333;
}
.cur {
	cursor: pointer;
}

/* 2021.09.08 */
.introduce {
	position: relative;
}
.details {
	display: inline-block;
	border-radius: 6px !important;
	width: 126px;
	height: 50px;
	background: #4467cf;
	color: #fff;
	font-family: "MicrosoftYaHei";
	font-size: 14px;
	text-indent: 0;
	text-align: center;
	line-height: 48px;
	position: absolute;
	bottom: 0;
}
.details_bg {
	position: absolute;
	/* width: 195px;
  height: 191px; */
	left: 234px;
	top: 685px;
	z-index: 10;
}
.details_video {
	position: relative;
	z-index: 11;
}

.contactus_div_bg {
	background: url("../assets/images/contactus_bg.png") no-repeat center;
	background-size: 100% 100%;
	width: 100%;
	height: 156px;
	position: absolute;
	top: 1240px;
	left: 0;
	z-index: 9;
}
/* 联系方式 */
.contactus_div {
	width: 144px;
	height: 174px;
	background: #fff;
	border-radius: 3px;
	/* margin: 0 13px; */
}
.contal img {
	margin-top: 20px;
	margin-bottom: 20px;
}

/* 参展企业 */
.exhibitors_carousel_width {
	width: 1200px;
}
.exhibitors_left {
	width: 471px;
	height: 508px;
	padding: 5px;
	background: #fff;
	border-radius: 3px;
	float: left;
}

.activity_right {
	width: 380px;
	margin-left: 10px;
	text-align: left;
	/* margin-bottom: 29px; */
}
.img_h {
	height: 52px;
}
.dis_in_bl {
	display: inline-block !important;
}
.conferenceIntroduction {
	margin-top: 20px;
	display: block;
	font-size: 12px;
	font-family: Microsoft YaHei, Microsoft YaHei-Regular;
}
#dynamic {
	position: relative;
	z-index: 10;
}
.dynamic_color_blue:hover {
	color: #182fb3;
}
#forun {
	position: relative;
}

.dhcontent::after {
	width: 390px;
	height: 390px;
	position: absolute;
	right: 0;
	top: 0;
	z-index: -1;
	content: "";
	background: url(../assets/images/details_bg.png) no-repeat;
	/* background-position: 10px 10px; */
	/* transform: rotate(180deg); */
}

.time_more {
	position: absolute;
	right: 0;
	top: 57px;
	background: rgba(78, 83, 197, 0.1);
	border-radius: 2px;
	width: 14px;
	height: 14px;
	padding: 0;
	opacity: 0.6;
	text-align: center;
	line-height: 14px;
	font-weight: 600;
}
.company_more {
	display: block;
	font-size: 16px;
	color: #333;
	line-height: 60px;
	width: 300px;
	height: 60px;
	background: #ffffff;
	border-radius: 30px;
	margin: 20px auto 0;
}
.contactus div:first-child {
	margin-left: 0 !important;
}
.contactus div:last-child {
	margin-right: 0 !important;
}
.el-carousel__arrow--right {
	right: 10px !important;
}
.forunTitle {
	height: 50px;
	line-height: 50px;
	display: block;
}
.bgDecorate {
	width: 100%;
	position: absolute;
	left: 0;
	top: 260px;
}
.bgDecorate3 {
	width: 100%;
	position: absolute;
	left: 0;
	top: 53%;
}
.floorplan {
	width: 720px;
}
</style>
