var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"style_bg_color_main",attrs:{"id":"box"}},[_c('topmenu',{attrs:{"comname":'index'}}),_c('div',{staticClass:"bgml",attrs:{"id":"introduce"}},[_c('img',{staticClass:"bgDecorate",attrs:{"src":require("@/assets/images/contactus_bg.png"),"alt":""}}),_c('div',{staticClass:"widht1200"},[_c('div',{attrs:{"id":"company"}},[_c('div',{staticClass:"widht1200"},[_c('div',{staticClass:"title",attrs:{"id":"onlineCloudExhibition"}},[_vm._v("线上云展")]),_c('el-carousel',{staticClass:"exhibitors_carousel_width",attrs:{"height":"520px","arrow":"always","indicator-position":"none"}},_vm._l((_vm.companyList),function(item1,index){return _c('el-carousel-item',{key:index,staticClass:"width_carousel"},[_c('ul',{staticClass:"compaylist width_carousel"},_vm._l((item1),function(item){return _c('li',{key:item.id,class:item.nvcCompanyName? 'bg-white': 'op0'},[(item)?[_c('a',{attrs:{"href":item.url,"rel":_vm.noFollow(item.url),"target":"_blank"}},[_c('el-image',{attrs:{"src":item.nvcLogo? item.nvcLogo: require('@/assets/images/dtmr.png'),"fit":"contain","alt":item.nvcLogo
															? item.nvcCompanyName
															: '',"title":item.nvcCompanyName}})],1),_c('a',{attrs:{"href":item.url,"rel":_vm.noFollow(item.url),"target":"_blank"}},[_c('span',{staticClass:"company u-line-1",attrs:{"title":item.nvcCompanyName},domProps:{"innerHTML":_vm._s(_vm.filterKeywords(item.nvcCompanyName))}})]),_c('span',{staticClass:"spanPublic"},[_vm._v(" "+_vm._s(item.nvcContactPerson)+" "+_vm._s(item.nvcContactPost)+" ")]),_c('span',{staticClass:"spanPublic"},[_vm._v(" 电话："+_vm._s(item.nvcContactNumber)+" ")]),_c('span',{staticClass:"productDetails",domProps:{"innerHTML":_vm._s(item.nvcProducts)}}),_c('a',{staticClass:"enter color182",attrs:{"href":item.url,"rel":_vm.noFollow(item.url),"target":"_blank"}},[_vm._v("点击进入展厅")])]:_vm._e()],2)}),0)])}),1),_c('router-link',{staticClass:"font14 company_more",attrs:{"to":{ name: 'enterprise', query: { id: 1 } }}},[_vm._v("查看更多")])],1)]),_c('div',{staticClass:"title",attrs:{"id":"conferenceIntroduction"}},[_vm._v("大会介绍")]),_c('div',{staticClass:"widht1200 introduce"},[_c('el-image',{staticStyle:{"height":"346px","width":"615px"},attrs:{"src":require('@/assets/images/hotel.jpg'),"preview-src-list":[
							require('@/assets/images/hotel.jpg') ],"fit":"cover"}}),_c('div',{staticClass:"fr dhcontent font14 style_color_ash"},[_c('div',{staticStyle:{"height":"256px","overflow":"hidden","line-height":"190%"},domProps:{"innerHTML":_vm._s(_vm.filterKeywords(_vm.introduce))}}),_c('br'),_c('br'),_c('router-link',{attrs:{"to":{ name: 'exintroduce', query: { id: 1 } }}},[_c('a',{staticClass:"details",attrs:{"href":"javascript:void(0);"}},[_vm._v("查看详情")])])],1)],1),_c('div',{staticClass:"title u-margin-top-30",attrs:{"id":"contactus"}},[_vm._v("联系方式")]),_c('div',{staticClass:"contactus"},[_vm._m(0),_c('div',{staticClass:"floorplan"},[_c('el-image',{staticStyle:{"height":"440px","width":"100%"},attrs:{"src":_vm.curUrl,"fit":"cover","preview-src-list":[_vm.curUrl]}})],1)])])]),_c('div',{attrs:{"id":"dynamic"}},[_c('div',{staticClass:"widht1200"},[_c('div',{staticClass:"title"},[_vm._v("大会动态")]),(_vm.dynamicList.length > 0)?_c('div',{staticClass:"listinfor"},_vm._l((_vm.dynamicList),function(item,index){return _c('div',{key:index},[(index === 0)?_c('div',{staticClass:"first fl"},[_c('a',{staticClass:"bgtsa",on:{"click":function($event){return _vm.jumpDteails(item)}}},[_c('el-image',{staticStyle:{"width":"380px","height":"200px"},attrs:{"src":item.vImgUrl
											? item.vImgUrl
											: require('@/assets/images/dtmr.png'),"alt":item.vName,"fit":"cover"}})],1),_c('div',{staticClass:"contm1"},[_c('div',{staticClass:"fl font16"},[_c('span',{staticStyle:{"cursor":"pointer"},attrs:{"title":item.vName},domProps:{"innerHTML":_vm._s(_vm.filterKeywords(item.vName))},on:{"click":function($event){return _vm.jumpDteails(item)}}}),_c('span',{staticClass:"font12 conferenceIntroduction"},[_vm._v(" "+_vm._s(item.vAbstract && item.vAbstract.length > 56 ? _vm.comJs.limitNum( item.vAbstract, 56 ) : item.vAbstract)+" "),(
												item.vAbstract &&
													item.vAbstract.length > 56
											)?_c('i',{staticClass:"color182 fontStyleNormal",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.jumpDteails(item)}}},[_vm._v("【more】")]):_vm._e()])])])]):_vm._e(),_c('div',{staticClass:"listcont fl"},[(index > 0)?_c('div',{staticClass:"list"},[_c('div',{staticClass:"contm"},[_c('a',{on:{"click":function($event){return _vm.jumpDteails(item)}}},[_c('span',{staticClass:"tlew font16 td dynamic_color_blue",attrs:{"title":item.vName},domProps:{"innerHTML":_vm._s(_vm.filterKeywords(item.vName))}})]),_c('div',{staticClass:"lun font14",domProps:{"innerHTML":_vm._s(_vm.filterKeywords(item.vAbstract))}}),_c('a',{staticClass:"time_more",on:{"click":function($event){return _vm.jumpDteails(item)}}},[_c('img',{attrs:{"width":"14","src":require("@/assets/images/arrow-right.png"),"alt":""}})])]),_c('div',{staticClass:"time fr dynamic_color_blue"},[_c('span',{staticClass:"font24 fr blue"},[_vm._v(" "+_vm._s(_vm._f("todate1")(_vm._f("formatDate")(item.dCreateTime)))+" ")]),_c('br'),_c('span',{staticClass:"font12 fr"},[_vm._v(" "+_vm._s(_vm._f("todate2")(_vm._f("formatDate")(item.dCreateTime)))+" ")])])]):_vm._e()])])}),0):_vm._e(),_c('router-link',{staticClass:"company_more font14",attrs:{"to":{ name: 'dynamic' }}},[_vm._v("查看更多")])],1)]),_c('div',{attrs:{"id":"forun"}},[_c('div',{staticClass:"widht1200 bg_forun"},[_c('div',{staticClass:"title",attrs:{"id":"foruminfor"}},[_vm._v("大会集锦")]),_c('div',{staticClass:"foruminfor"},[_c('ul',_vm._l((_vm.collectionList),function(item){return _c('li',{key:item.id,staticStyle:{"width":"386px","height":"260px"}},[_c('el-card',{staticStyle:{"height":"260px"},attrs:{"body-style":{ padding: '5px' }}},[(item.videoUrl)?_c('video',{staticStyle:{"height":"260px","width":"386px"},attrs:{"controls":"","controlslist":"nodownload","poster":item.imgUrl,"src":item.videoUrl}}):_c('el-image',{staticStyle:{"width":"100%","height":"250px"},attrs:{"fit":"cover","src":item.imgUrlMin
											? item.imgUrlMin
											: item.imgUrl,"preview-src-list":[
										item.imgUrlMin
											? item.imgUrlMin
											: item.imgUrl ]}})],1)],1)}),0),_c('router-link',{staticClass:"company_more font14",attrs:{"to":{ name: 'specimens' }}},[_vm._v("查看更多")])],1)])]),_c('div',{staticClass:"mt15"},[_c('bottominfo')],1),(_vm.ishowsa)?_c('div',{staticClass:"t_menulist"},[_c('ul',[_c('li',{class:_vm.hoded == 6 ? 't_xzhouer' : '',on:{"click":function($event){return _vm.getejfl('conferenceIntroduction', 6)}}},[_vm._v("大会介绍")]),_c('li',{class:_vm.hoded == 2 ? 't_xzhouer' : '',on:{"click":function($event){return _vm.getejfl('contactus', 2)}}},[_vm._v("联系方式")]),_c('li',{class:_vm.hoded == 3 ? 't_xzhouer' : '',on:{"click":function($event){return _vm.getejfl('dynamic', 3)}}},[_vm._v("大会动态")]),_c('li',{class:_vm.hoded == 5 ? 't_xzhouer' : '',on:{"click":function($event){return _vm.getejfl('forun', 5)}}},[_vm._v("大会集锦")]),_c('li',{staticClass:"t-gotop",on:{"click":function($event){return _vm.getejfl('box', 4)}}},[_c('img',{attrs:{"src":require('@/assets/images/jiantou.png')}}),_vm._v("TOP ")])])]):_vm._e(),_c('img',{staticClass:"bgDecorate3",attrs:{"src":require("@/assets/images/bgDecorate.png"),"alt":""}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contactInformation"},[_c('div',{staticClass:"contal contactus_div"},[_c('img',{attrs:{"src":require("@/assets/images/contal_1.png"),"alt":""}}),_c('span',{staticClass:"font16 style_color_main"},[_vm._v("- 参展热线 -")]),_c('p',{staticClass:"font16 style_color_ash"},[_vm._v(" 冯卫章 "),_c('span',{staticClass:"dis_in_bl font16"},[_vm._v("199-1234-8021")])])]),_c('div',{staticClass:"contal contactus_div"},[_c('img',{staticClass:"img_h",attrs:{"src":require("@/assets/images/contal_2.png"),"alt":""}}),_c('span',{staticClass:"font16 style_color_main"},[_vm._v("- 观展预约 -")]),_c('p',{staticClass:"font16 style_color_ash"},[_vm._v(" 郜彦良 "),_c('span',{staticClass:"dis_in_bl font16"},[_vm._v("199-1234-8056")])])]),_c('div',{staticClass:"contal contactus_div"},[_c('img',{staticClass:"img_h",attrs:{"src":require("@/assets/images/contal_3.png"),"alt":""}}),_c('span',{staticClass:"font16 style_color_main"},[_vm._v("- 媒体合作 -")]),_c('p',{staticClass:"font16 style_color_ash"},[_vm._v(" 张祥柏 "),_c('span',{staticClass:"dis_in_bl font16"},[_vm._v("177-3233-5075")])])]),_c('div',{staticClass:"contal contactus_div"},[_c('img',{staticClass:"img_h",attrs:{"src":require("@/assets/images/contal_4.png"),"alt":""}}),_c('span',{staticClass:"font16 style_color_main"},[_vm._v("- 会议地址 -")]),_c('span',{staticClass:"font14 style_color_ash"},[_vm._v(" 山西省吕梁市孝义市 "),_c('br'),_vm._v("崇文大街181号 ")])])])}]

export { render, staticRenderFns }